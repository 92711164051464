import generateUUID from './guid';
import { isTimeStale, oneDayFromTodayUnixTimestamp } from './time';

const doctorFinderSearchedEvent = async (data) => {
  const guidKey = window.swfinder.guidKey;
  let guid = localStorage.getItem(guidKey);

  const event = new CustomEvent('swOssio', {
    detail: {
      ...data,
      eventName: 'doctor_finder_search',
      guid: guid,
      currentTimestamp: Math.floor(Date.now() / 1000),
    },
  });
  window.dispatchEvent(event);

  gtag('event', 'doctor_finder_search', data);
};

export { doctorFinderSearchedEvent };
